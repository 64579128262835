<template>
  <main>
    <div>
      <keep-alive>
        <top-nav/>
      </keep-alive>
    </div>
    <b-container class="mt-4">
      <b-row>
        <b-col v-if="pending">
        </b-col>
        <b-col v-else>
          <b-table-simple small responsive="" bordered class="bg-white">
            <b-thead>
              <b-tr>
                <b-th colspan="9">
                  <div>
                    <filter-component
                        :filters="filters"
                        :filterData="filter"
                        :selectedId="selectedId"
                        :statusFilters="status"
                        v-on:search="search"
                    />
                  </div>
                </b-th>
              </b-tr>
              <b-tr>
                <b-th>userId</b-th>
                <b-th>결제 일시</b-th>
                <b-th>상태</b-th>
                <b-th>금액</b-th>
<!--                <b-th>견적서</b-th>-->
                <b-th>결제방식</b-th>
                <b-th>증빙서류</b-th>
                <b-th>발급일자</b-th>
                <b-th>발급신청</b-th>
                <b-th>결제취소</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="list.length > 0">
              <b-tr
                  v-for="payment in list"
                  :key="`event-${payment._id}`"
                  :class="selectedId === payment._id ? 'active' : ''"
              >
                <b-td>
                  {{payment.userId}}
                </b-td>
                <b-td>
                  {{payment.formatRegDate}}
                </b-td>
                <b-td>
                  {{payment.message}}
                </b-td>
                <b-td>
                  {{payment.success ? `${new Intl.NumberFormat('ko-KR').format(payment.price)}원` : null}}
                </b-td>
                <b-td>
                  {{payment.success ? payment.method_name : null}}
                </b-td>
                <b-td v-html="payment.method === 'card' ? '신용카드 매출전표' : ''"></b-td>
                <b-td>
                  {{payment.method === 'card' ? '' : ''}}
                </b-td>
                <b-td>
                  <a v-if="payment.method && payment.receipt_url && !payment.cancel" class="btn btn-secondary" :href="payment.receipt_url" target="_black" style="font-size:12px; padding:0 3px 0 3px">print</a>
                </b-td>
                <b-td>
                  <button v-if="payment.status_en ==='complete' && !payment.cancel"
                     class="btn btn-danger"
                     style="font-size:12px; padding:0 3px 0 3px"
                     v-on:click="bootpayCancel(payment)">cancel</button>
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                <b-td colspan="9">결제 내역이 존재하지 않습니다.</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination-nav
              v-if="list.length"
              :link-gen="linkGen"
              v-model="page"
              :number-of-pages="perPage"
              use-router
              aria-controls="my-table"
              first-number
              last-number
              first-text="«"
              prev-text="‹"
              next-text="›"
              last-text="»"
              align="right"
              size="sm"
          />
        </b-col>
      </b-row>
    </b-container>

    <div>
      <b-modal
          v-model="cancelModal"
          no-close-on-backdrop
          size="lg"
          title="결제 취소"
          cancel-title="취소"
          ok-title="신청"
          @ok="cancelSave"
          :okDisabled="cancelPending"
          :cancel-disabled="cancelPending"
          :hide-header-close="cancelPending"
          @cancel="bootpayModalClose"
          @close="bootpayModalClose">
        <div v-if="cancelUserInfo">
          userId : {{cancelUserInfo.userId}}<br>
          취소금액 : {{cancelUserInfo.price}}<br>
          SamPoint 차감 : {{cancelUserInfo.samPoint}}<br>
          bonusPoint 차감 : {{cancelUserInfo.bonusPoint}}
        </div>
        <div class="mt-4">
          <b-form-group
              label="취소자 명"
              label-for="textarea-formatter"
              description="취소자명을 입력하여 주세요."
              class="mb-0"
          >
            <b-form-input type="text" v-model="cancel.name"></b-form-input>
          </b-form-group>
        </div>
        <div class="mt-4">
          <b-form-group
              label="취소 사유"
              label-for="textarea-formatter"
              description="취소 사유를 입력하여 주세요."
              class="mb-0"
          >
            <b-form-textarea
                id="textarea-formatter"
                v-model="cancel.reason"
                placeholder="입력하여 주세요"
                rows="3" max-rows="5"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </b-modal>
    </div>
  </main>
</template>

<script>
import filterComponent from '@/components/filter'
export default {
  name: "payment",
  components: {
    filterComponent
  },
  data: () => ({
    pending: false,
    page: 1,
    total: 0,
    perPage: 0,
    list: [],

    filters: [
      {text: '선택', value: null},
      {text: 'ID', value: 'userId'}
    ],
    status: [
      {text: '상태', value: null},
      {text: '결제 완료', value: 1},
      {text: '결제 취소', value: 20}
    ],
    filter: {
      searchFilter: null,
      searchValue: null,
      searchStatus: null,
      perPage: 10,
    },
    selectedId: null,
    cancelModal: false,
    cancelUserInfo: null,
    cancel: {
      name: null,
      reason: null
    },
    cancelPending: false
  }),
  created() {
    const {_id} = this.$route.params
    if (_id) this.selectedId = _id
    const { page, searchFilter, searchValue, searchStatus, perPage } = this.$route.query
    if (page && !isNaN(+page)) this.page = +page
    if (searchFilter) this.filter.searchFilter = searchFilter
    if (searchValue) this.filter.searchValue = searchValue
    if (searchStatus) this.filter.searchStatus = searchStatus
    if (perPage && !isNaN(+perPage)) this.filter.perPage = +perPage
    this.load(this.page)
  },
  methods: {
    async load (pageNo) {
      try {
        this.page = pageNo ? isNaN(+pageNo) ? 1 : +pageNo : 1
        const params = {
          page: this.page,
          ...this.filter
        }

        const {data} = await this.axios({
          url: `/admin/payment-history/list`,
          params
        })

        const {result, list, page, total, totalPage} = data

        if (result) {
          this.list = list
          this.page = page
          this.total = total
          this.perPage = totalPage
        }
      } catch (e){}
    },
    linkGen (page) {
      return `${this.$route.path}?page=${page}&${Object.entries(this.filter).map(v => v[1] ? `${v[0]}=${v[1]}` : `${v[0]}`).join('&')}`
    },

    bootpayCancel (info) {
      this.cancelModal = true
      this.cancelUserInfo = info
    },

    bootpayModalClose () {
      this.cancelModal = false
      this.cancelUserInfo = null
      this.cancel = {
        name: null,
        reason: null
      }
      this.cancelPending = false
    },

    async cancelSave (bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.cancel.name === null || this.cancel.name.trim() === "") {
        this.$toasted.error('취소자명을 입력하여 주세요.', {duration: 3000})
        return
      }

      if (this.cancel.reason === null || this.cancel.reason.trim() === "") {
        this.$toasted.error('취소 사유를 입력하여 주세요.', {duration: 3000})
        return
      }

      try {
        this.cancelPending = true
        const {data} = await this.axios({
          url: `admin/payment/cancel`,
          method: "patch",
          data: {
            info: this.cancelUserInfo,
            cancel: this.cancel
          }
        })

        const {result, error} = data
        if (!result) {
          this.$toasted.error(error, {duration: 3000})
          this.cancelPending = false
          return
        }

        this.$toasted.success('결제를 취소하였습니다.', {duration: 3000})
        this.bootpayModalClose()
        this.cancelModal = false
        await this.load(this.page)
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style scoped>
main {
  min-height: 100vh;
}
th, td {
  font-size: 0.8rem;
  vertical-align: middle;
}
tr.active {
  color: red;
  font-weight: bold;
  background-color: #F5F5F5;
}
</style>
